var OApp;
define(["Dwr", "dwr/interface/UserSessionRpc", "OUtils", "sentry"], function(Dwr, UserSessionRpc, OUtils) { "use strict";
	var sessionTimeoutHandler = null;
	var overrideSessionTimeout = false;
	var timeoutWarningHandler = null;
	var resetTime = Date.now();
	var dwrCount = 0;
	var loopAlreadyReported = false;

	var cachedEnvironmentInfo;
	function saveEnvironmentInfo(sentryInitObject) {
		cachedEnvironmentInfo = {
			environment: sentryInitObject.environment,
			release: parseInt(sentryInitObject.release),
			revision: parseInt(sentryInitObject.tags.Revision)
		}
	}
	
	function handleSessionExpiry() {
		if (sessionTimeoutHandler) {
			sessionTimeoutHandler();
		} else {
			OUtils.needsSignInToContinue(function(user) {
				var siteNum = OUtils.getURLVars()["siteNum"];
				if (user && siteNum !== user.defaultSiteNum) {
					OApp.changeToSite(siteNum, function() {
						location.reload();
					});
				} else {
					// refresh the page
					location.reload();
				}
			});
		}
	}

	var DWREngine = (function() {
		var currentSiteNum = null;
		
		function reverseAjaxPollStatusHandler(newStatus, ex, maxRetriesReached) {
			console.log("reverseAjaxPollStatusHandler: " + newStatus + ", " + ex + ", " + maxRetriesReached);
			if (maxRetriesReached) {
				OUtils.showNote(OUtils.t("Connection to Ocean server temporarily lost. Please click OK to renew your session.", "OApp.js.connection_to_ocean_server_lost"), {
					title: OUtils.t("Renew Session", "OApp.js.renew_session"), 
					modal: true, 
					callback: function() {
						location.reload();
					}
				});
			}
		}
		function DWRError(msg, exception) {
			OUtils.hideWait();
			if (dwr.engine && dwr.engine._pollBatch && dwr.engine._pollBatch.isPoll) {
				// this is probably a server shutdown failure - redirect to login page
				OUtils.showNote(OUtils.t("Your server session has ended. Please click OK to log in again.", "OApp.js.server_session_ended"), {
					title: OUtils.t("Session Ended", "OApp.js.session_ended"), 
					modal: true, 
					callback: function() {
						location.reload();
					}
				});
				return;
			}
			if ($("#PopupLogin").length > 0 || exception.name == "dwr.engine.incompleteReply") {
				//presumably this was a session-expired error; ignore
				return;
			}

			var exceptionClassName = exception.exceptionClassName;
			if (exceptionClassName && exceptionClassName.endsWith("SessionRedirectException")) {
				return;
			}
			//To find out the origin of the error, you might want to set a breakpoint in DefaultRemoter.writeExceptionToAccessLog
			var errorMsg = "An error occurred while communicating with the server: " + msg;
			if (exception) {
				OApp.logException(exception);
			}
			OUtils.showError(errorMsg, null, {
				width: "auto",
				title: "Server Error",
				create: function() {
					$(this).css("maxWidth", "800px");
				},
			});
		}
		function init(spec) {
			UserSessionRpc.getSentryInitInfo(function(sentryInitObj) {
				// Cache some environment information so we can easily get it later without a remote call
				saveEnvironmentInfo(sentryInitObj);

				if (sentryInitObj.enabled) {
					// Disable the automatic Sentry integration into global error handlers.
					// We want to handle the exceptions ourselves. See https://github.com/getsentry/sentry-javascript/issues/2019
//					sentryInitObj.integrations = [
//					    new Sentry.Integrations.GlobalHandlers({ 
//					      onunhandledrejection: false,
//					      onerror: false
//					    })
//					];
					// The above approach doesn't seem to work as expected. This completely removes these default integrations.
					sentryInitObj.integrations = function(integrations) {
						for (var i = 0; i < integrations.length; i++) {
							// replace Sentry Breadcrumbs integration with one that doesn't log console output 
							// to prevent accidental data leakage if someone leaves in some debug logging.
							if (integrations[i].name === "Breadcrumbs") {
								integrations[i] = new Sentry.Integrations.Breadcrumbs({ console: false });
								break;
							}
						}
						return integrations
							// Disable global error handler
							.filter(function(i) {return i.name !== 'GlobalHandlers'})
							// Disable other integrations
							.filter(function(i) {return i.name !== 'TryCatch'});
					};
					function sentryFilter(event) {
						// chop off any anchor tags as this is where we often store the encryption key or other sensitive information
						var anchorIdx = event.request.url.indexOf("#");
						if (anchorIdx !== -1) {
							event.request.url = event.request.url.substring(0, anchorIdx) + "#REDACTED4SENTRY";
						}

						return event;
					};
					sentryInitObj.beforeSend = sentryFilter;
					try {
						Sentry.init(sentryInitObj);
						Sentry.setTags(sentryInitObj.tags);
					} catch (e) {
						console.log("Failed to initialize Sentry");
						console.log(e);
					}
				}
			});

			if (!spec) {
				spec = {};
			}
			// start reverse ajax unless explicitly told not to
			if (spec.activateReverseAjax !== false) {
				// enable reverse Ajax
				dwr.engine.setActiveReverseAjax(true);
				dwr.engine.setPollStatusHandler(reverseAjaxPollStatusHandler);
				// ensure the server shuts down any reverse Ajax ScriptSessions
				dwr.engine.setNotifyServerOnPageUnload(true, true);
			}
			dwr.engine.setPreHook(function() {
				if (dwr.engine._headers === null) {
					dwr.engine._headers = {};
				}
				dwr.engine._headers.browsersitenum = currentSiteNum;
			
				if (!loopAlreadyReported) {
					var timeSinceReset = Date.now() - resetTime;
					if (timeSinceReset > 60000) {
						resetTime = Date.now();
						dwrCount = 0;
					}
					
					dwrCount++;
					if (dwrCount > 500) {
						// more than 500 DWR calls in 60 seconds, report loop and stop
						loopAlreadyReported = true;
						var err = new Error(OUtils.t("Reporting potential DWR loop: > 500 calls in 60 seconds", "OApp.js.reporting_potential_DWR_loop"));
						err.silent = true;
						OApp.reportError(err);
					}
				} 	
			});
			dwr.engine._debug = function(message, stacktrace) {
				console.log(message);
				if (stacktrace) {
					console.log(stacktrace);
				}
				if (message.stack) {
					console.log(message.stack);
				}

				//FIXME_I18N analyzing exception text
				if ("Exception occured in user-specified handler:" === message) {
					return;
				}

				// don't generate noise if there's no logged in user.
				// hide noise from DWR offline errors
				if (!dwr.engine._unloading && !/^DWR Offline|^DWR Online|^poll retry|^max retries/.test(message)) {
					try { OApp.reportError(message); }
					catch (e) {}//eg RPC call failed; may need to continue for full error log
				}
			};
		}

		if (typeof(dwr) !== "undefined") {
			dwr.engine.setErrorHandler(DWRError);
			// self defense for reverse ajax loop bug
			dwr.engine.setMaxRetries(10);
			dwr.engine.setTextHtmlHandler(handleSessionExpiry);
		}
		function setSiteNum(siteNum) {
			try {
				Sentry.setTag("SiteNum", siteNum);
			} catch (e) {
				console.log("Failed to set Sentry tag: ", siteNum);
				console.log(e);
			}
			currentSiteNum = siteNum;
		}
		return {
			init: init,
			setSiteNum: setSiteNum,
			DWRError: DWRError
		};
	})();
	
OApp = (function() {
	var hasInit = false;
	var appUser = null;
	var userLoaded = $.Deferred();
	var signedIn = $.Deferred();
	var cachedSite = null;
	var siteUsers = null;
	var siteTimeZone = null;
	var alreadyRedirecting = false;
	var _sessionExpiryDate = null;
	var _sessionCurrentTime = null;
	var _sentExpiryWarning = false;
	var _checkSessionExpiryInterval = 5000; // in milliseconds
	var _sessionExpiryTimerId = setTimeout(checkSessionExpiryTimeout, _checkSessionExpiryInterval);

	// Find the session expiry headers and save the most up to date expiry time
	// Headers are found by extending xhr.open() to listen for DWR calls and read their response headers
	(function(open) {
		XMLHttpRequest.prototype.open = function(XMLHttpRequest, url) {
			var self = this;
			var sessionExpiryHeader = 'Session-Expiry';
			var sessionCurrentTimeHeader = 'Session-Current-Time';
			if(url.indexOf('dwr') > -1) {
				this.addEventListener('readystatechange', function() {
					// readyState 4 means xhr operation completed, See https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState
					if (this.readyState === 4 && self.getResponseHeader(sessionExpiryHeader)) {
						_sessionExpiryDate = new Date(self.getResponseHeader(sessionExpiryHeader).split(',')[0]);
						_sessionCurrentTime = new Date(self.getResponseHeader(sessionCurrentTimeHeader).split(',')[0]);
						self = null;
					}
				}, false);
			} else {
				self = null;
			}
			open.apply(this, arguments);
		};
	})(XMLHttpRequest.prototype.open);

	// Running the session expiry checking as an interval by recalling a timeout
	function checkSessionExpiryTimeout() {
		if (_sessionExpiryDate != null){
			checkSessionExpiry();
		} else {
			_sessionExpiryTimerId = setTimeout(checkSessionExpiryTimeout, _checkSessionExpiryInterval);
		}
	}
	
	function checkSessionExpiry() {
		// The following times are in milliseconds
		var remainingSessionTime = _sessionExpiryDate - _sessionCurrentTime;
		var timeUntilWarning = 6 * 60 * 1000; // 6 minutes
		var timeUntilLogout = 0.5 * 60 * 1000; // 30 seconds
		_sessionExpiryTimerId = setTimeout(checkSessionExpiryTimeout, _checkSessionExpiryInterval);
		if (remainingSessionTime <= timeUntilWarning && !_sentExpiryWarning) {
			showExpiryReminder();
		}
		if (remainingSessionTime <= timeUntilLogout) {
			clearTimeout(_sessionExpiryTimerId);
			// to be safe, only use sessionTimeoutHandler here if specifically enabled
			// previous uses of sessionTimeoutHandler still used logout in this case
			if (sessionTimeoutHandler && overrideSessionTimeout) {
				sessionTimeoutHandler();
			} else {
				logout();
			}
		}
	}

	function showExpiryReminder() {
		_sentExpiryWarning = true;
		if (timeoutWarningHandler) {
			// pass in a callback to reset warning display flag when custom warning is actioned
			timeoutWarningHandler(function() {
				_sentExpiryWarning = false;
			});
		} else {
			var warningText = OUtils.t('Your session will time out in 5 minutes due to inactivity. After this time, you will be redirected to log in again. Please note that any unsaved or incomplete activity may be lost.', 'default.timeout.warningText');
			var popupPanel = $('<div>' + warningText + '<div/>').appendTo($('body'));
			var popup = OUtils.showPopup(popupPanel, {
				title: OUtils.t('Are you still there?', 'default.timeout.questionForUser'),
				width: 600,
				autoClose: false,
				buttons: [{
					text: OUtils.t("Yes, I'm still here", 'default.timeout.userResponse'),
					click: renewSessionExpiry,
				}],
				closeCallback: clearSessionExpiryDialog,
				closeBox: false,
				show: true,
				modal: true
			});
		}
	}
	
	function renewSessionExpiry() {
		// Just close the dialog because closing the dialog indicates that you are there for the session to be renewed
		$(this).dialog("close");
	}
	
	function clearSessionExpiryDialog() {
		// Renew the session on all dialog closes since someone was there to close the dialog (whether they clicked the renew button or clicked escape)
		UserSessionRpc.renewSessionExpiry();
		_sentExpiryWarning = false;
	}

	function getUserProperName(callback, spec) {
		getUser(function(user) {
			spec = spec || {};
			spec.user = user;
			callback(getProperName(spec));
		});
	}
	function getProperName(args) {
		var user = args.user;
		if (user.anonymous){
			if (user.emrUserName){
				return user.emrUserName;
			}
			else {
				// This is an Anonymous E-Request User. Shouldn't ever be called but this will return "Anonymous Patient User"
				return user.firstName + " " + user.surname;
			}
		}
		else {
			if (user.physician) {
				if (args.full) {
					return OUtils.t("Dr. {{firstName}} {{surname}}", "OApp.js.dr_full_name", {firstName: user.firstName, surname: user.surname});
				} else {
					return OUtils.t("Dr. {{surname}}", "OApp.js.dr_surname", {surname: user.surname});
				}				
			}
			else {
				return user.firstName + " " + user.surname;
			}
		}
	}
	function getUser(callback) {
		if (!hasInit) {
			init();
		}
		userLoaded.then(callback);
	}
	function getUserRpc(cb) {
		require(["dwr/interface/UserRpc"], cb);
	}
	function setProviderName(providerName, callback) {
		getUser(function(user) {
			getUserRpc(function(UserRpc) {
				UserRpc.setProviderName(providerName, function() {
					user.providerName = providerName;
					if (callback) callback();
				});
			});
		});
	}
	function getUserPref(prefKey, callback) {
		getUser(function(user) {
			if (user && user.prefs) {
				callback(user.prefs[prefKey]);
			} else {
				callback(null);
			}
		});
	}
	function setUserPref(prefKey, value, callback) {
		OApp.getUser(function(user) {
			getUserRpc(function(UserRpc) {
				UserRpc.setPref(prefKey, value, function() {
					user.prefs[prefKey] = value;
					if (callback) callback();
				});
			});
		});
	}
	function getSitePromise(skipDefaultErrorHandler) {
		// OApp.getSite() in promise form; pass skipDefaultError=true to enable Promise.fail() handler
		return $.Deferred(function(d) {
			getSite(d.resolve, skipDefaultErrorHandler ? function errorHandler(error, e) {
				d.reject(error, e);
			} : null);
		});
	}
	function getCurrentSiteTimeZone() {
		return siteTimeZone;
	}
	function getSite(callback, errorHandler) {
		if (cachedSite) {
			callback(cachedSite);
		} else {
			getSiteNum(function(siteNum) {
				// if we don't have a user at this point, don't make the call
				if (appUser) {
					requirejs(["dwr/interface/SiteInfoRpc"], function() {
						SiteInfoRpc.getSiteInfo(siteNum, {callback: function(site) {
							cachedSite = site;
							siteTimeZone = site == null ? null : site.timeZoneID;
							DWREngine.setSiteNum(site === null ? null : site.siteNum);
							callback(site);
						}, errorHandler: errorHandler || null});
					});
				} else {
					callback(null);
				}
			});
		}
	}
	function getSiteUsers(callback) {
		if (siteUsers) {
			callback(siteUsers);
			return;
		}
		requirejs(["dwr/interface/SiteSettingsRpc"], function() {
			SiteSettingsRpc.getBasicSiteUserInfo(function(users) {
				siteUsers = users;
				callback(siteUsers);
			});
		});
	}
	function getSiteName(callback) {
		getUser(function(user) {
			if (!user || !user.sites) {
				callback(null);
				return;
			}
			for (var i = 0; i < user.sites.length; i++) {
				if (user.sites[i].siteNum == user.currentSiteNum) {
					callback(user.sites[i].siteName);
					return;
				}
			}
			getSiteNum(function(siteNum) {
				requirejs(["dwr/interface/SiteInfoRpc"], function() {
					SiteInfoRpc.getSiteInfo(siteNum, function(siteInfo) {
						callback(siteInfo ? siteInfo.siteName : null);
					});
				});
			});
		});
	}
	function getSiteNum(callback) {
		OApp.getUser(function(user) {
			var urlSiteNum = OUtils.getURLVars()["siteNum"];
			if (!user) {
				callback(urlSiteNum);
			} else {
				if (urlSiteNum && urlSiteNum !== user.currentSiteNum) {
					OApp.changeToSite(urlSiteNum, function() {
						location.reload();
					});
					return;//bail & wait for reload
				}
				callback(user.currentSiteNum);
			}
		});
	}
	function getSiteConfigProperty(property, callback) {
		getSite(function(site) {
			if(site) {
				if(site.siteConfig) {
					callback(site.siteConfig[property]);
				} else {
					callback(null);
				}
			} else {
				throw OUtils.t("Current site not available", "OApp.js.current_site_not_available");
			}
		});
	}
	function logout(redirectURL) {
		console.log("logging out...");
		setTimeout(function() {
			var logoutURL = "/logout";
			OApp.getUser(function(user) {
				if (user && OUtils.getCookie("regionalSsoActive") === "true") {
					window.location.replace("/sso/oneid/logout");
				}
				else if (redirectURL) {
					$.get(logoutURL, function() {
						window.location.replace(redirectURL);
					});
				}
				else {
					window.location.replace(logoutURL);				
				}
			});
		}, 100);
	}
	function sessionEnded(msg) {
		if (sessionTimeoutHandler) {
			sessionTimeoutHandler(msg);
		} else {
			forceLogin(msg);
		}
	}
	function sessionRedirect(siteNum) {
		// The line below is quite important. If an error occurs during handling of sessionRedirect, it can
		// lead to an infinite client loop since a retried DWR call could lead to an additional sessionRedirect
		// call and so on. I was able to trace a failure in this function (e.g. OUtils undefined) to a retry
		// due to the dwr.engine._debug function but not to the repeated UserSessionRpc.getUser calls seen in 
		// production. Removing siteNum should prevent that particular loop from recurring in the future.
		DWREngine.setSiteNum(null);
		if (alreadyRedirecting) {
			return;
		}
		alreadyRedirecting = true;
		
		if (!OUtils) {
			// guard against strange order of operations issues here
			doRedirect();
			return;
		} else {
			OUtils.showNote(OUtils.t("Your site number has changed on the server. Click OK to redirect to site {{newSiteNum}}.", "OApp.js.site_number_changed", {newSiteNum: newSiteNum}),
				{
					title: OUtils.t("Site Changed", "OApp.js.site_changed"), 
					modal: true, 
					callback: doRedirect
				}
			);
		}
		
		function doRedirect() {
			var pathName = location.pathname;
			if (	pathName === "/ocean/portal.html" ||
					pathName === "/referrals/NewReferral.html" ||
					pathName === "/user/UserAccount.html") {
				window.location = location.origin + pathName + "?siteNum=" + siteNum;
			}
		}
	}
	function forceLogin(msg) {
		console.log("redirecting to login page...");
		OUtils.setLocalStorage("LOGIN_MSG", msg);
		window.location.replace("/");
	}
	function loadUser() {
		UserSessionRpc.getUser(function(user) {
			appUser = user;
			userLoaded.resolve(user);
			if (user && !user.anonymous) {
				signedIn.resolve(user);
			}
		});
	}
	function reloadUser(callback) {
		userLoaded = $.Deferred();
		loadUser();
		getUser(callback);
	}
	function onerror(msg, filename, line, col, exc) {
		reportError(exc);
	}
	function init(spec) {
		if (!hasInit) {
			// for DWR loop bug reporting
			resetTime = Date.now();
			dwrCount = 0;
			loopAlreadyReported = false;

			// set up global catch-all JS exception handler
			window.onerror = onerror;
			sessionTimeoutHandler = spec ? spec.sessionTimeoutHandler : null;
			overrideSessionTimeout = spec ? spec.overrideSessionTimeout : false;
			timeoutWarningHandler = spec ? spec.timeoutWarningHandler : null;
			DWREngine.init(spec);
			OUtils.activateCaching();
			loadUser();
			initBootstrap();
			hasInit = true;
			if (spec && spec.callback) {
				spec.callback();
			}
		}
		OUtils.i18nInit(null, function(t) {});
	}
	function initBootstrap() {
		$.widget( "ui.autocomplete", $.ui.autocomplete, {
			_resizeMenu: function() {
				  $(this.menu.element).addClass("dropdown-menu");
			}
		});
	}
	function reportVueError(exception, vm, info) {
		var vnodeTag = (vm && vm.$vnode) ? vm.$vnode.tag : OUtils.t("unknown Vnode", "OApp.js.unknown_vnode"); 
		var vueMsg = OUtils.t("Vue error: \"{{exceptionMessage}}\", from {{vnodeTag}} during {{info}}" , "OApp.js.vue_error", {exceptionMessage: exception.message, vnodeTag: vnodeTag, info: info});
		exception.message = vueMsg;
		reportError(exception);
	}
	function shouldCaptureSentryException(exception) {
		// plain object detection code shamelessly stolen from Sentry
		if (Object.prototype.toString.call(exception) !== '[object Object]') {
			return true;
		}
		
		// these are "plain object" exceptions - look for DWR networking issues & suppress
		if (exception.name && exception.name.indexOf("dwr.engine.") === 0) {
			return false;
		} else {
			return true;
		}
	}
	function reportError(exception) {
		if (!exception || exception.suppressReport) {
			return;
		}
		logException(exception);
		if (exception.message) {
			// Ignore weird Chrome bug that floods us with error reports 
			if (exception.message.indexOf("__gCrWeb.autofill.extractForms") !== -1) {
				return;
			}
			// ignore dialog close errors
			//FIXME_I18N analyzing exception text
			if (exception.message.indexOf("cannot call methods on dialog prior to initialization") !== -1) {
				return;
			}
			// Ignore require.js script errors in production 
			//FIXME_I18N analyzing exception text
			if ((exception.message.indexOf("Script error for") === 0 || exception.message.indexOf("Load timeout for modules") === 0)
					&& (window.location.host === "ocean.cognisantmd.com" || window.location.host === "oceanhealthmap.ca" || window.location.host === "oceanhealthlinks.ca")) {
				return;
			}
			
			//FIXME_I18N analyzing exception text
			if (exception.message.indexOf("bad date format") !== -1) {
				return;
			}
			// ignore missing template errors due to noise
			//FIXME_I18N analyzing exception text
			if (exception.message.indexOf("missing template: #") !== -1) {
				return;
			}
		}
		try {
			if (shouldCaptureSentryException(exception)) {
				Sentry.captureException(exception);
			}
		} catch (e) {
			console.log("Failed to log Sentry exception");
			console.log(e);
		}
		if (exception.message) {
			UserSessionRpc.reportJavaScriptException(exception.message, exception.stack);
		} else if (exception.stack) {
			//FIXME_I18N sent to server
			UserSessionRpc.reportJavaScriptException("No message provided", exception.stack);
		} else {
			if (appUser) {
				//FIXME_I18N sent to server
				UserSessionRpc.reportJavaScriptException("No exception or stack provided: " + exception, null);
			}
		}
		if (!exception.silent) {
			OUtils.showError(
				OUtils.t("We're sorry, an error has occurred. The error has been reported to OceanMD support:", "OApp.js.error_reported_to_oceanmd") + 
				"<BR><BR>" + 
				exception
			);
		}
	}
	function signIn(opts) {
		return $.Deferred(function(d) { ensureSignedIn(d.resolve, opts)});
	}
	function ensureSignedIn(callback, opts) {
		opts = opts || {};
		getUser(function(user) {
			if (!user || !user.userName || (opts.noAnonUsers && user.anonymous)) {
				showPopup();
			} else {
				//ensure not timed out
				UserSessionRpc.getUser(function(user) {
					var disallowedAnonUser = user && user.anonymous && opts.noAnonUsers;
					if (!user || disallowedAnonUser) {
						showPopup();
					} else {
						callback(user);
					}
				});
			}
			function showPopup() {
				OUtils.showPopupLogin(function(user) {
					if (user) {
						callback(user);
					}
				}, opts);
			}
		});
	}
	function showAnonymousLoginPrompt(callback) {
		OApp.getUser(function(user) {
			if (user && user.anonymous) {
				requirejs(["anonLogin"], function(AnonLogin) {
					AnonLogin.displayAnonymousLoginCue("#anonUser", callback);					
				});
			}
		});
	}
	function hideAnonymousLoginPrompt() {
		requirejs(["anonLogin"], function(AnonLogin) {
			AnonLogin.hideSignInPrompt("#anonUser");					
		});
	}
	function isSiteAdmin(cb) {
		UserSessionRpc.isSiteAdmin(function(siteAdmin) {
			if (cb) {
				cb(siteAdmin);
			}
		});
	}
	function logException(exception) {
		if (exception.message) {
			console.error(exception.message);
		}
		if (exception.stack) {
			console.error(exception.stack);
		} else {
			console.error(exception);
		}
	}
	function changeToSite(siteNum, callback) {
		console.log("changeToSite: " + siteNum);
		requirejs(["dwr/interface/SiteSettingsRpc"], function(SiteSettingsRpc) {
			SiteSettingsRpc.updateCurrentSiteNum(siteNum, function() {
				if (appUser) {
					appUser.currentSiteNum = siteNum;
				}
				getSite(callback);
			});
		});
	}
	function getEnvironmentInfo(callback) {
		if (!cachedEnvironmentInfo) {
			UserSessionRpc.getSentryInitInfo( function(info) {
				saveEnvironmentInfo(info);
				callback(cachedEnvironmentInfo);
			});
		} else {
			callback(cachedEnvironmentInfo);
		}
	}
	function getHelpHeroInitialization(callback){
		requirejs(["dwr/interface/SiteSettingsRpc"], function(SiteSettingsRpc) {
			SiteSettingsRpc.getHelpHeroInitialization(function(helpHeroInitialization) {
				callback(helpHeroInitialization);
			});
		});
	}
	return {
		init: init,
		getUser: getUser,
		reloadUser: reloadUser,
		ensureSignedIn: ensureSignedIn,
		signIn: signIn,
		signedIn: function() { return signedIn; },
		setProviderName: setProviderName,
		getUserPref: getUserPref,
		setUserPref: setUserPref,
		getSiteNum: getSiteNum,
		getCurrentSiteTimeZone: getCurrentSiteTimeZone,
		getSite: getSite,
		getSitePromise: getSitePromise,
		getSiteUsers: getSiteUsers,
		getSiteName: getSiteName,
		getSiteConfigProperty: getSiteConfigProperty,
		getUserProperName: getUserProperName,
		getProperName: getProperName,
		logout: logout,
		forceLogin: forceLogin,
		sessionEnded: sessionEnded, //needed for DwrScriptSessionListener to invoke
		sessionRedirect: sessionRedirect, //needed for DwrScriptSessionListener to invoke
		handleSessionExpiry: handleSessionExpiry, //needed for DwrScriptSessionListener to invoke
		reportError: reportError,
		DWRError: DWREngine.DWRError,
		logException: logException,
		showAnonymousLoginPrompt: showAnonymousLoginPrompt,
		hideAnonymousLoginPrompt: hideAnonymousLoginPrompt,
		isSiteAdmin: isSiteAdmin,
		changeToSite: changeToSite,
		reportVueError: reportVueError,
		getEnvironmentInfo: getEnvironmentInfo,
		getHelpHeroInitialization: getHelpHeroInitialization
	 };
})();
return OApp;
});